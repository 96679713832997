/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, TextField, Grid, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import { useAppDispatch } from '../../store/store';
import { clearInputForm } from '../../store/slices/input-form-slice';
import { MainLeft, MainRight } from '../../styles/styled';
import IconArrow from '../../assets/icon-arrow-btn.png';
import IconSearch from '../../assets/icon-search-btn.png';
import IconTicket from '../../assets/icon-ticket-id.png';
import Image from '../../components/Image';
import { env } from '../../adapters/environmentConfigs';
import { tracking } from '../../services/appsheet-service';
import { ITrackingResponse } from '../../models/tracking-model';
import LoadingModal from '../../components/LoadingModal';
import { decryptData } from '../../utils/encode-decode'

const HomePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openLoadingModal, setOpenLoadingModal] = React.useState(false);
  const [token, setToken] = useState('');

  React.useEffect(() => {
    dispatch(clearInputForm());
  }, []);

  const [valueTicket, setValueTicket] = useState('');
  const [verified, setVerify] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const regex = /[^a-zA-Z0-9-]/g;
    const newValue = inputValue.replace(regex, '');
    setValueTicket(newValue);
  };

  function onReCaptchaChange(value: any) {
    if (value) {
      setVerify(true);
      setToken(value);
    }
  }

  function onSubmit() {
    if (verified && valueTicket) {
      callAPIAppsheet();
    }
  }

  function onRecaptchaExpired() {
    setVerify(false);
  }

  async function callAPIAppsheet() {
    setOpenLoadingModal(true);
    await tracking(valueTicket, token)
      .then((result: ITrackingResponse[]) => {
        if (result.length === 0) {
          navigate('/error', { state: { type: 'tracking' } });
        } else {
          navigate('/success', { state: { type: 'tracking', data: result[0] } });
        }
      })
      .catch((error:any) => {
          console.log('API request failed:', error);
          navigate('/error', { state: { type: 'tracking-error', data: error } });
      })
      .finally(() => setOpenLoadingModal(false));
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <MainLeft>
            <Typography component="h2">เสนอพื้นที่เช่าซีเจ มอร์</Typography>
            <Typography
              variant="subtitle1"
              component="p"
              sx={{ fontSize: { xs: '14px', sm: '16px' }, color: '#000', fontWeight: 500 }}
            >
              ทำเลที่มีศักยภาพตรงความต้องการของทางบริษัท
            </Typography>
            <Button component={Link} to="/step1" variant="contained" endIcon={<Image src={IconArrow} width="32" />}>
              เสนอพื้นที่เช่า
            </Button>
          </MainLeft>
        </Grid>
        <Grid item xs={12} md={6}>
          <MainRight>
            <Typography component="h2">ติดตามสถานะการเสนอพื้นที่เช่า</Typography>
            <Typography
              variant="subtitle1"
              component="p"
              sx={{ fontSize: { xs: '14px', sm: '16px' }, color: '#000', fontWeight: 500 }}
            >
              ติดตามสถานะการเสนอพื้นที่เช่าโดยใช้ Ticket ID ในการค้นหา
            </Typography>
            <TextField
              placeholder="Ticket ID"
              value={valueTicket}
              onChange={handleChange}
              inputProps={{
                pattern: '[a-zA-Z0-9]+',
                title: 'Only letters (uppercase and lowercase) and numbers are allowed, no special characters.',
              }}
              InputProps={{
                startAdornment: <Image src={IconTicket} width="34" style={{ margin: '0 12px 0 5px' }} />,
              }}
              sx={{ width: '367px', maxWidth: '100%' }}
            />

            <ReCAPTCHA
              sitekey={decryptData(env.googleCaptcha.siteKey)}   
              onChange={onReCaptchaChange}
              onExpired={onRecaptchaExpired}
            />

            <Button
              variant="contained"
              disabled={!verified}
              startIcon={<Image src={IconSearch} width="23" />}
              onClick={onSubmit}
            >
              ติดตามสถานะ
            </Button>
          </MainRight>
        </Grid>
      </Grid>
      <LoadingModal open={openLoadingModal} />
    </>
  );
};

export default HomePage;
