export const apiPath = {
  appSheet: {
    area: {
      url: '/SiteOfferItems/Action',
    },
    tracking: {
      url: '/SiteOffer_View_Translated/Action',
    },
  },
};
