export class ApiError {
  code: any;
  httpStatus: number;
  message: string;

  constructor(httpStatus: number, code: any, message: string) {
    this.code = code;
    this.httpStatus = httpStatus;
    this.message = message;
  }
}