import * as yup from 'yup';
const nameCharacter = /^[0-9a-zA-Zก-๙ ]+$/;
const specialCharacter = /^[0-9a-zA-Zก-๙ .()\n]*$/;

export const step1ValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('โปรดระบุ')
    .matches(nameCharacter, 'ห้ามมีอักขระพิเศษ'),
  lastName: yup
    .string()
    .required('โปรดระบุ')
    .matches(nameCharacter, 'ห้ามมีอักขระพิเศษ'),
  telephone: yup
    .string()
    .required('โปรดระบุ')
    .matches(/^\d{10}$/, 'format เบอร์โทรไม่ถูกต้อง'),
  email: yup.string().email('format อีเมลไม่ถูกต้อง').required('โปรดระบุ'),
  offerType: yup
      .string()
      .required('โปรดระบุ'),
});

export const step2ValidationSchema = yup.object().shape({
  province: yup.string().required('โปรดระบุ'),
  district: yup.string().required('โปรดระบุ'),
  subDistrict: yup.string().required('โปรดระบุ'),
  landWidth: yup
    .string()
    .required('โปรดระบุ')
    .test('greaterThan15', 'หน้ากว้างน้อยกว่าเงื่อนไขที่บริษัทฯกำหนด', (value) => {
      const newValue = value?.replace(',', '');
      return parseFloat(newValue || '0') >= 20;
    }),
  landDepth: yup
    .string()
    .required('โปรดระบุ')
    .test('greaterThan1', 'ความลึกน้อยกว่าเงื่อนไขที่บริษัทฯกำหนด', (value) => {
      const newValue = value?.replace(',', '');
      return parseFloat(newValue || '0') >= 1
    }),
  landAreaSQM: yup
    .string()
    .required('โปรดระบุ')
    .test('greaterThan800', 'ขนาดพื้นที่เช่าน้อยกว่าเงื่อนไขที่บริษัทฯกำหนด', (value) => {
      const newValue = value?.replace(',', '');
      return parseFloat(newValue || '0') >= 800;
    }),
  landDeedNumbers: yup
    .string()
    .required('โปรดระบุ')
    .matches(/^[0-9,]+$/, 'โปรดระบุ ตัวเลข หรือเครื่องหมาย comma เท่านั้น'),
  nearbyPOI: yup
    .string()
    .required('โปรดระบุ')
    .matches(specialCharacter, 'ห้ามมีอักขระพิเศษ'),
  isAreaCleared: yup.string().required('โปรดระบุ'),
  demolitionDetails: yup.string().when('isAreaCleared', (val) => {
    if (val === '0') {
      return yup
        .string()
        .required('โปรดระบุ')
        .matches(specialCharacter, 'ห้ามมีอักขระพิเศษ');
    } else {
      return yup.string().notRequired();
    }
  }),
  landDetails: yup
    .string()
    .matches(specialCharacter, 'ห้ามมีอักขระพิเศษ')
    .notRequired(),
});

export const step3ValidationSchema = yup.object().shape(
  {
    expectedRent: yup
      .string()
      .required('โปรดระบุ')
      .matches(/^[0-9,]+$/, 'กรุณากรอกแต่ตัวเลข')
      .max(10, 'ตัวเลขไม่เกิน 8 หลัก')
      .test('greaterThan1', 'ค่าเช่าน้อยกว่าเงื่อนไขที่บริษัทฯกำหนด', (value) => {
        const newValue = value?.replace(',', '');
        return parseFloat(newValue || '0') >= 1
      }),
    fromChannel: yup
      .string()
      .required('โปรดระบุ')
      .matches(/^[0-9a-zA-Zก-๙ /]+$/, 'ห้ามมีอักขระพิเศษ'),
    fromOtherChannel: yup.string().when('fromChannel', (val, schema) => {
      if (val === '5') {
        return yup
          .string()
          .max(100, 'ไม่เกิน 100 อักษร')
          .required('โปรดระบุ')
          .matches(/^[0-9a-zA-Zก-๙ ]+$/, 'ห้ามมีอักขระพิเศษ');
      } else {
        return yup.string().notRequired();
      }
    }),
  }
);
