import React from 'react';

interface ImageProps {
  src: string;
  alt?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onLoad?: () => void;
  onError?: () => void;
}

function Image({ alt, ...props }: ImageProps) {
  return <img alt={alt} {...props} />;
}

export default Image;
