/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  MenuItem,
  Select,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { GoogleMap, useJsApiLoader, Autocomplete, Marker } from '@react-google-maps/api';

import { env } from '../../adapters/environmentConfigs';
import { useAppSelector, useAppDispatch } from '../../store/store';
import { ColRightStep } from '../../styles/styled';
import Image from '../../components/Image';
import CustomStepBar from './components/CustomStepBar';
import { step2ValidationSchema } from '../../utils/validateSchema';
import { updateInputForm } from '../../store/slices/input-form-slice';
import AreaCondition from './components/AreaCondition';
import {
  findProvince,
  findDistricts,
  findSubDistricts,
  IProvince,
  IDistrict,
  ISubDistrict,
  findLocationCode,
} from '../../utils/locationCode';
import Placeholder from '../../components/Placeholder';
import IconArrow from '../../assets/icon-arrow-btn.png';
import IconClose from '../../assets/icon-close.png';
import IconBack from '../../assets/icon-back.png';
import IconSearch from '../../assets/icon-input-search.png';
import BtnMap from '../../assets/btn-map.png';
import IconMarker from '../../assets/icon-marker.png';
import { AreaClearedOptions } from '../../utils/areaCleared';
import { decryptData } from '../../utils/encode-decode'

interface IFormInputs {
  province: string;
  district: string;
  subDistrict: string;
  landWidth: string;
  landDepth: string;
  landAreaSQM: string;
  landDeedNumbers: string;
  nearbyPOI: string;
  isAreaCleared: string;
  demolitionDetails: string;
  landDetails: string;
  lat: number;
  lng: number;
}
const StepTwoPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inputSelector = useAppSelector((state) => state.inputFormSlice);

  useEffect(() => {
    if(!inputSelector.firstName || !inputSelector.lastName || !inputSelector.telephone || !inputSelector.email || !inputSelector.offerType) {
      navigate('/');
    }
  }, [])
  

  const [openMap, setOpenMap] = useState(false);
  const [provinceCode, setProvinceCode] = useState<string>('');
  const [locationCode, setLocationCode] = useState<string>('');
  const [districts, setDistricts] = useState<IDistrict[]>([]);
  const [subdistricts, setSubDistricts] = useState<ISubDistrict[]>([]);
  const [disableDemolition, setDisableDemolition] = useState(true);
  const [geolocation, setGeolocation] = useState({ lat: -1, lng: -1, displayError: false });

  // MAP
  const defaultCenter = { lat: 13.726396126939521, lng: 100.52883967313281 };
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [center, setCenter] = useState({ lat: -1, lng: -1 });
  const [markerPosition, setMarkerPosition] = useState({ lat: -1, lng: -1 });
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: decryptData(env.googleMap.googleMapsApiKey),
    libraries: ['places'],
  });

  const onMapLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmountMap = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const mapOptions = {
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  const onMapDragEnd = () => {
    if (map) {
      const newCenter = map.getCenter();

      if (newCenter?.lat !== undefined && newCenter?.lng !== undefined) {
        setCenter({
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        });
      }
    }
  };

  const onPlaceLoad = (data: any) => {
    setAutocomplete(data);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      setCenter({ lat, lng });
      setMarkerPosition({ lat, lng });
      setGeolocation({ lat, lng, displayError: false });
    } else {
      console.info('Autocomplete is not loaded yet!');
    }
  };

  const onMarkerDragEnd = (event: any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setMarkerPosition({ lat, lng });
    setGeolocation({ lat, lng, displayError: false });
  };
  // END MAP

  const handleClickOpen = () => {
    setOpenMap(true);
  };

  const handleClose = () => {
    setOpenMap(false);
  };

  const handleCloseBySubmit = () => {
    setCenter({ lat: markerPosition.lat, lng: markerPosition.lng });
    setGeolocation({ lat: markerPosition.lat, lng: markerPosition.lng, displayError: false });
    setOpenMap(false);
  };

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(step2ValidationSchema),
  });

  const handleValidationMap = () => {
    if (geolocation.lat < 0 || geolocation.lng < 0) {
      setGeolocation({ ...geolocation, displayError: true });
    }
  };

  const onSubmit = (data: IFormInputs) => {
    if (geolocation.lat < 0 || geolocation.lng < 0) {
      setGeolocation({ ...geolocation, displayError: true });
    } else {
      const payload = { ...data, locationCode: locationCode, lat: geolocation.lat, lng: geolocation.lng };
      dispatch(updateInputForm(payload));
      navigate('/step3');
    }
  };

  useEffect(() => {
    setValue('province', inputSelector.province);
    setValue('district', inputSelector.district);
    setValue('subDistrict', inputSelector.subDistrict);
    setValue('landWidth', inputSelector.landWidth);
    setValue('landDepth', inputSelector.landDepth);
    setValue('landAreaSQM', inputSelector.landAreaSQM);
    setValue('landDeedNumbers', inputSelector.landDeedNumbers);
    setValue('nearbyPOI', inputSelector.nearbyPOI);
    setValue('isAreaCleared', inputSelector.isAreaCleared);
    setValue('demolitionDetails', inputSelector.demolitionDetails);
    setValue('landDetails', inputSelector.landDetails);

    if (inputSelector.province) {
      setDistricts(findDistricts(inputSelector.province));
      setProvinceCode(inputSelector.province);
    }

    if (inputSelector.province && inputSelector.district) {
      const findSub = findSubDistricts(inputSelector.province, inputSelector.district);
      if (findSub.length > 0) setSubDistricts(findSub);

      const findCode = findLocationCode(findSub, inputSelector.subDistrict);
      if (findCode.length > 0) setLocationCode(findCode[0].location_code);
    }

    if (inputSelector.isAreaCleared === '0') {
      setDisableDemolition(false);
    } else {
      setDisableDemolition(true);
      setValue('demolitionDetails', '');
    }

    if (inputSelector.lat > 0 && inputSelector.lng > 0) {
      const lat = inputSelector.lat;
      const lng = inputSelector.lng;

      setCenter({ lat, lng });
      setMarkerPosition({ lat, lng });
      setGeolocation({ lat, lng, displayError: false });
    }
  }, []);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={5} sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex' } }}>
          <AreaCondition />
        </Grid>
        <Grid item xs={12} md={7}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <ColRightStep>
              <Typography component="h2">แบบฟอร์มสำหรับเสนอพื้นที่เช่า</Typography>

              <CustomStepBar step={2} />

              <Grid container rowSpacing={{ xs: 0.5, sm: 1, lg: 2 }} columnSpacing={{ xs: 2, sm: 2, lg: 6 }} sx={{ maxWidth: '100%' }}>
                <Grid item xs={12} md={4} sx={gridStyle}>
                  <Label>จังหวัด</Label>

                  <Select
                    {...register('province')}
                    displayEmpty
                    sx={selectStyle}
                    error={errors.province ? true : false}
                    defaultValue={inputSelector.province || ''}
                    onChange={(event: SelectChangeEvent) => {
                      clearErrors('province');
                      setValue('province', event.target.value);
                      setValue('district', '');
                      setValue('subDistrict', '');
                      setProvinceCode(event.target.value);
                      setDistricts(findDistricts(event.target.value));
                    }}
                  >
                    <MenuItem disabled value="">
                      <Placeholder text="กรุณาเลือกจังหวัด" />
                    </MenuItem>

                    {findProvince().map((item: IProvince) => (
                      <MenuItem key={item.province_code} value={item.province_code}>
                        {item.province}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography component="p" sx={errorBox}>
                    {errors.province && errors.province.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={gridStyle}>
                  <Label>อำเภอ</Label>
                  <Select
                    {...register('district')}
                    displayEmpty
                    error={errors.district ? true : false}
                    sx={selectStyle}
                    value={getValues('district') !== undefined ? getValues('district') : ''}
                    onChange={(event: SelectChangeEvent) => {
                      clearErrors('district');
                      setValue('district', event.target.value);
                      setValue('subDistrict', '');
                      setSubDistricts(findSubDistricts(provinceCode, event.target.value));
                    }}
                  >
                    <MenuItem disabled value="">
                      <Placeholder text="กรุณาเลือกอำเภอ" />
                    </MenuItem>

                    {districts.map((item: IDistrict) => (
                      <MenuItem key={item.district_code} value={item.district_code}>
                        {item.district}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography component="p" sx={errorBox}>
                    {errors.district && errors.district.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{...gridStyle, mb:{xs:2,sm:0} }}>
                  <Label>ตำบล</Label>
                  <Select
                    {...register('subDistrict')}
                    displayEmpty
                    error={errors.subDistrict ? true : false}
                    sx={selectStyle}
                    value={getValues('subDistrict') !== undefined ? getValues('subDistrict') : ''}
                    onChange={(event: SelectChangeEvent) => {
                      clearErrors('subDistrict');
                      setValue('subDistrict', event.target.value);
                      const filterCode = subdistricts.filter((e) => e.subdistrict_code === event.target.value);
                      setLocationCode(filterCode[0].location_code);
                    }}
                  >
                    <MenuItem disabled value="">
                      <Placeholder text="กรุณาเลือกตำบล" />
                    </MenuItem>

                    {subdistricts.map((item: ISubDistrict) => (
                      <MenuItem key={item.subdistrict_code} value={item.subdistrict_code}>
                        {item.subdistrict}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography component="p" sx={errorBox}>
                    {errors.subDistrict && errors.subDistrict.message}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={4} sx={gridStyle}>
                  <Label>หน้ากว้างพื้นที่เช่า (เมตร)</Label>

                  <Controller
                    name="landWidth"
                    control={control}
                    render={({ field }) => {
                      const { onChange, onBlur, value, name, ref } = field;
                      return (
                        <NumericFormat
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          name={name}
                          value={value}
                          decimalScale={2}
                          fixedDecimalScale
                          thousandSeparator=","
                          customInput={TextField}
                          error={errors.landWidth ? true : false}
                          sx={textFieldStyle}
                          inputProps={{
                            maxLength: 13,
                          }}
                          allowNegative={false}
                        />
                      );
                    }}
                  />
                  <Typography component="p" sx={errorBox}>
                    {errors.landWidth && errors.landWidth.message}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} sx={gridStyle}>
                  <Label>ความลึกพื้นที่เช่า (เมตร)</Label>

                  <Controller
                    name="landDepth"
                    control={control}
                    render={({ field }) => {
                      const { onChange, onBlur, value, name, ref } = field;
                      return (
                        <NumericFormat
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          name={name}
                          value={value}
                          decimalScale={2}
                          fixedDecimalScale
                          thousandSeparator=","
                          customInput={TextField}
                          error={errors.landDepth ? true : false}
                          sx={textFieldStyle}
                          inputProps={{
                            maxLength: 13,
                          }}
                          allowNegative={false}
                        />
                      );
                    }}
                  />

                  <Typography component="p" sx={errorBox}>
                    {errors.landDepth && errors.landDepth.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={gridStyle}>
                  <Label>ขนาดพื้นที่เช่า (ตร.ม.)</Label>

                  <Controller
                    name="landAreaSQM"
                    control={control}
                    render={({ field }) => {
                      const { onChange, onBlur, value, name, ref } = field;
                      return (
                        <NumericFormat
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          name={name}
                          value={value}
                          decimalScale={2}
                          fixedDecimalScale
                          thousandSeparator=","
                          customInput={TextField}
                          error={errors.landAreaSQM ? true : false}
                          sx={textFieldStyle}
                          inputProps={{
                            maxLength: 13,
                          }}
                          allowNegative={false}
                        />
                      );
                    }}
                  />

                  <Typography component="p" sx={errorBox}>
                    {errors.landAreaSQM && errors.landAreaSQM.message}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container rowSpacing={{ xs: 0, sm: 1, lg: 2 }} columnSpacing={{ xs: 2, sm: 2, lg: 6 }} sx={{ maxWidth: '100%' }}>
                <Grid item xs={12} md={8}>
                  <Grid container rowSpacing={{ xs: 0.5, sm: 1, lg: 2 }} columnSpacing={{ xs: 2, sm: 2, lg: 6 }}>
                    <Grid item xs={12} md={6} sx={{...gridStyle, mt:{xs:-1.5,sm:0} }}>
                      <Label>เลขที่โฉนด</Label>
                      <TextField
                        {...register('landDeedNumbers')}
                        error={errors.landDeedNumbers ? true : false}
                        sx={textFieldStyle}
                      />
                      <Typography component="p" sx={errorBox}>
                        {errors.landDeedNumbers && errors.landDeedNumbers.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={gridStyle}>
                      <Label>ชื่อบริเวณสถานที่ใกล้เคียง</Label>
                      <TextField
                        {...register('nearbyPOI')}
                        error={errors.nearbyPOI ? true : false}
                        sx={textFieldStyle}
                      />
                      <Typography component="p" sx={errorBox}>
                        {errors.nearbyPOI && errors.nearbyPOI.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{...gridStyle, mt:{xs:2,sm:0} }}>
                      <Label>เป็นพื้นที่โล่งหรือไม่</Label>
                      <Select
                        {...register('isAreaCleared')}
                        error={errors.isAreaCleared ? true : false}
                        sx={selectStyle}
                        defaultValue={inputSelector.isAreaCleared || ''}
                        displayEmpty
                        onChange={(event: SelectChangeEvent) => {
                          clearErrors('isAreaCleared');

                          if (event.target.value !== '1') {
                            setDisableDemolition(false);
                          } else {
                            setDisableDemolition(true);
                            setValue('demolitionDetails', '');
                            clearErrors('demolitionDetails');
                          }
                        }}
                      >
                        <MenuItem disabled value="">
                          <Placeholder text="กรุณาเลือก" />
                        </MenuItem>

                        {AreaClearedOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography component="p" sx={errorBox}>
                        {errors.isAreaCleared && errors.isAreaCleared.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={gridStyle}>
                      <Label>มีงานรื้อถอนอะไรบ้าง </Label>

                      <TextField
                        {...register('demolitionDetails')}
                        error={errors.demolitionDetails ? true : false}
                        disabled={disableDemolition}
                        sx={textFieldStyle}
                        multiline
                        maxRows={3}
                        inputProps={{ maxLength: 300 }}
                      />

                      <Typography component="p" sx={errorBox}>
                        {errors.demolitionDetails && errors.demolitionDetails.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={gridStyle}>
                      <Label>รายละเอียดเพิ่มเติมเกี่ยวกับพื้นที่เช่า</Label>
                      <TextField
                        {...register('landDetails')}
                        error={errors.landDetails ? true : false}
                        sx={textareaFieldStyle}
                        multiline
                        maxRows={3}
                        inputProps={{ maxLength: 300 }}
                      />
                      <Typography component="p" sx={errorBox}>
                        {errors.landDetails && errors.landDetails.message}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} sx={{...gridStyle, mt:{xs:1,sm:0} }}>
                  <Label>ระบุตำแหน่งที่ตั้งของพื้นที่เช่า</Label>
                  <Button
                    onClick={handleClickOpen}
                    sx={{ padding: 0, borderRadius: '1rem', maxWidth: { xs: '100%', sm: '300px' } }}
                  >
                    <Image src={BtnMap} width="100%" />
                  </Button>

                  {geolocation.displayError || geolocation.lat < 0 || geolocation.lng < 0 ? (
                    <Typography component="p" sx={errorBox}>
                      {geolocation.displayError && 'โปรดระบุ'}
                    </Typography>
                  ) : (
                    <>
                      {geolocation.lat > 0 && (
                        <Typography component="p" sx={geoStyle}>
                          Latitude : {geolocation.lat}
                        </Typography>
                      )}
                      {geolocation.lng > 0 && (
                        <Typography component="p" sx={geoStyle}>
                          Longitude : {geolocation.lng}
                        </Typography>
                      )}
                    </>
                  )}
                  {/* <Typography component="p" sx={errorBox}>
                    {geolocation.displayError && 'โปรดระบุ'}
                  </Typography>

                  {geolocation.lat > 0 && <Typography component="p" sx={geoStyle}>
                    Latitude : {geolocation.lat}
                  </Typography>}
                  {geolocation.lng > 0 && <Typography component="p" sx={geoStyle}>
                    Longitude : {geolocation.lng}
                  </Typography>} */}
                </Grid>
              </Grid>

              <Box
                display="flex"
                sx={{
                  gap: '10px',
                  flexFlow: {xs:'column-reverse wrap', md:'row nowrap'},
                  justifyContent: 'center',
                  marginTop: 4,
                  position: 'sticky',
                  bottom: 0,
                  px:{xs:1,md:0},
                  width: '100%',
                  boxShadow: '0 -12px 0 -7px rgba(250, 252, 253, 0.75)',
                  backgroundColor: '#FAFCFD',
                }}
              >
                <Button component={Link} to="/step1" variant="outlined">
                  ย้อนกลับ
                </Button>

                <Button
                  type="submit"
                  onClick={() => handleValidationMap()}
                  variant="contained"
                  endIcon={<Image src={IconArrow} width="32" />}
                >
                  ถัดไป
                </Button>
              </Box>
            </ColRightStep>

            <Dialog
              fullScreen={fullScreen}
              open={openMap}
              aria-labelledby="responsive-dialog-title"
              fullWidth={true}
              maxWidth={'md'}
              sx={{
                zIndex: 'inherit',
                '& .MuiPaper-rounded': {
                  borderRadius: { xs: '0', md: '10px' },
                  padding:{xs:'10px 0',md:'10px'},
                },
                padding: {xs:'0',md:'10px'},
              }}
            >
              <AppBar elevation={0} sx={{ position: 'relative', height: '32px', background: '#fff', p: 0 }}>
                <Toolbar sx={{ p: '0!important', m: 0, height: '32px', minHeight: '32px!important' }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ display: { xs: 'block', md: 'none' }, p: 0, ml: 1 }}
                  >
                    <Image src={IconBack} height="32" />
                  </IconButton>
                  <Typography
                    sx={{ ml: { xs: 2, md: 1 }, flex: 1, fontSize: '16px', fontWeight: 600, color: '#000' }}
                    variant="h6"
                    component="div"
                  >
                    ระบุตำแหน่งที่ตั้งของพื้นที่เช่า
                  </Typography>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ display: { xs: 'none', md: 'block' }, p: 0 }}
                  >
                    <Image src={IconClose} height="24" />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent sx={{ py: {xs:0, md:2}, px: { xs: 0, md: 1 } }}>
                <Box
                  display={'flex'}
                  sx={{
                    position: { xs: 'absolute', md: 'relative' },
                    zIndex: 2,
                    background: { xs: '#FAFCFD', md: 'none' },
                    left: 0,
                    right: 0,
                    width: { xs: '80%', sm: '70%', md: 'auto' },
                    maxWidth: '100%',
                    padding: { xs: '5px', md: '0' },
                    margin: { xs: '10px auto 0', md: '0 auto 10px' },
                    boxShadow: { xs: '0px 0px 8px rgba(0, 0, 0, 0.1)', md: 'none' },
                    borderRadius: { xs: '5px', md: '0' },
                    '&>div': { minWidth: { xs: '100%', md: '40%' } },
                  }}
                >
                  <Autocomplete onLoad={onPlaceLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                      type="text"
                      placeholder="ค้นหาสถานที่"
                      style={{
                        width: '100%',
                        padding: '0 5px 0 38px',
                        lineHeight: '34px',
                        border: '1px solid #CBCBCB',
                        borderRadius: '5px',
                        background: `url(${IconSearch}) 10px 50% no-repeat`,
                        backgroundSize: 'auto 18px',
                        fontFamily: 'Noto Sans Thai',
                        fontSize: '14px',
                      }}
                    />
                  </Autocomplete>

                  {/* <Button variant="contained" sx={{ height: '36px', marginLeft: '10px', width: { xs:'100%', md:'120px'} }}>
                    ค้นหา
                  </Button> */}
                </Box>

                {isLoaded ? (
                  <Box sx={{ height: { xs: 'calc(100vh - 110px)', md: '423px' } }}>
                    <GoogleMap
                      id="options-map"
                      mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '5px' }}
                      center={center.lat > 0 && center.lng > 0 ? center : defaultCenter}
                      zoom={16}
                      onLoad={onMapLoad}
                      onUnmount={onUnmountMap}
                      options={mapOptions}
                      clickableIcons={false}
                      onDragEnd={onMapDragEnd}
                    >
                      <Marker
                        icon={IconMarker}
                        position={markerPosition.lat > 0 && markerPosition.lng > 0 ? markerPosition : defaultCenter}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                      />
                    </GoogleMap>
                  </Box>
                ) : (
                  <Box />
                )}
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  display={'flex'}
                  sx={{
                    position: { xs: 'absolute', md: 'relative' },
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    background: { xs: '#00A9DE', md: 'none' },
                    width: { xs: '60%', md: 'auto' },
                    minWidth: { xs: '300px', md: 'inherit' },
                    top: { xs: '120px', md: 'auto' },
                    left: 0,
                    right: 0,
                    margin: { xs: '0 auto', md: 0 },
                    borderRadius: { xs: '20px', md: 0 },
                    padding: { xs: '5px 10px', md: 0 },
                    boxShadow: { xs: '0px 0px 8px rgba(0, 0, 0, 0.1)', md: 'none' },
                    color: { xs: '#fff', md: '#000' },
                    gap: { xs: 1, md: 3 },
                  }}
                >
                  <Typography sx={{ fontSize: { xs: 12, md: 16 }, fontWeight: { xs: 600, md: 500 } }}>
                    Latitude: {geolocation.lat > 0 ? geolocation.lat : '-'}
                  </Typography>
                  <Typography sx={{ fontSize: { xs: 12, md: 16 }, fontWeight: { xs: 600, md: 500 } }}>
                    Longitude: {geolocation.lng > 0 ? geolocation.lng : '-'}
                  </Typography>
                </Box>

                <Button
                  onClick={handleCloseBySubmit}
                  autoFocus
                  variant="contained"
                  disabled={geolocation.lat < 0 && geolocation.lng < 0}
                  sx={{ height: '36px', marginLeft: '10px', width: {xs:'100%', md:'120px'} }}
                >
                  ตกลง
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

const Label = styled('label')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '18px',
  marginBottom: '5px',
  color: theme.palette.primary.dark,
}));

const gridStyle = {
  display: 'flex',
  flexFlow: 'column wrap',
};

const selectStyle = {
  padding: '0 5px',
  lineHeight: '24px',
  height: '33px',
  maxWidth: '100%',
  fontSize: '14px',
  '& div': {
    fontSize: '14px',
  },
};

const textFieldStyle = {
  '& input': {
    padding: '0 5px',
    lineHeight: '24px',
    height: '24px',
    maxWidth: '100%',
    fontSize: '14px',
  },
  '&>.MuiInputBase-root>.MuiSelect-select': {
    padding: '0 0 0 5px',
    fontSize: '14px',
  },
  '& textarea': {
    padding: '0',
    // lineHeight: '24px',
    minHeight: '24px',
    fontSize: '14px',
    resize: 'vertical',
  },
};

const textareaFieldStyle = {
  '& input': {
    padding: '0 5px',
    lineHeight: '24px',
    height: '24px',
    maxWidth: '100%',
    fontSize: '14px',
  },
  '& textarea': {
    padding: '0',
    // lineHeight: '24px',
    minHeight: '48px',
    fontSize: '14px',
    resize: 'vertical',
  },
};

const errorBox = {
  fontSize: '12px',
  color: '#EB3223',
  lineHeight: '15px',
  marginTop: '5px',
  minHeight: '15px',
};

const geoStyle = {
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '15px',
  marginTop: '5px',
  minHeight: '15px',
};

export default StepTwoPage;
