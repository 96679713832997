import { Box } from '@mui/material';
import { StepBar } from '../../../styles/styled';

interface CustomStepProps {
  step?: number;
}

const CustomStepBar = ({ step = 1 }: CustomStepProps) => {
  return (
    <StepBar>
      <li className="active">
        <Box component="i">1</Box>
        <Box component="span">ข้อมูลทั่วไป</Box>
      </li>
      <li className={step >= 2 ? 'active' : ''}>
        <Box component="i">2</Box>
        <Box component="span">ข้อมูลพื้นที่</Box>
      </li>
      <li className={step >= 3 ? 'active' : ''}>
        <Box component="i">3</Box>
        <Box component="span">ข้อมูลเพิ่มเติม</Box>
      </li>
    </StepBar>
  );
};

export default CustomStepBar;
