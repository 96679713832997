import { Routes, Route, Outlet } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/home/HomePage';
import StepOnePage from './pages/step/StepOnePage';
import StepTwoPage from './pages/step/StepTwoPage';
import StepThreePage from './pages/step/StepThreePage';
import SummaryPage from './pages/summary/SummaryPage';
import SuccessPage from './pages/result/SuccessPage';
import { Box } from '@mui/material';
import ErrorPage from './pages/result/ErrorPage';

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="step1" element={<StepOnePage />} />
          <Route path="step2" element={<StepTwoPage />} />
          <Route path="step3" element={<StepThreePage />} />
          <Route path="summary" element={<SummaryPage />} />
          <Route path="success" element={<SuccessPage />} />
          <Route path="error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </div>
  );
};

function Layout() {
  return (
    <>
      <Navbar />
      <Box sx={{ minHeight: 'calc(100vh - 216px)', display: 'flex', alignItems: 'stretch' }}>
        <Outlet />
      </Box>
      <Footer />
    </>
  );
}

export default App;
