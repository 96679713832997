import province from './data/province.json';
import district from './data/district.json';
import subDistrict from './data/subDistrict.json';

export interface IProvince {
  province_code: string;
  province: string;
}

export interface IDistrict {
  province_code: string;
  district_code: string;
  district: string;
}

export interface ISubDistrict {
  province_code: string;
  district_code: string;
  subdistrict_code: string;
  location_code: string;
  subdistrict: string;
}

export const findProvince = () => {
  return province;
};

export const findDistricts = (provinceCode: string) => {
  const filter = district.filter((e) => e.province_code === provinceCode);
  return filter;
};

export const findSubDistricts = (provinceCode: string, districtCode: string) => {
  const filter = subDistrict.filter((e) => e.province_code === provinceCode && e.district_code === districtCode);
  return filter;
};

export const findLocationCode = (subDistricts: ISubDistrict[], subDistrictCode: string) => {
  const filter = subDistricts.filter((e) => e.subdistrict_code === subDistrictCode);
  return filter;
};

export const findProvinceName = (provinceCode: string) => {
  const value = province.filter((e) => e.province_code === provinceCode);
  return value[0].province;
};

export const findDistrictName = (provinceCode: string, districtCode: string) => {
  const value = district.filter((e) => e.province_code === provinceCode && e.district_code === districtCode);
  return value[0].district;
};

export const findSubDistrictName = (provinceCode: string, districtCode: string, subDistrictCode: string) => {
  const value = subDistrict.filter(
    (e) =>
      e.province_code === provinceCode && e.district_code === districtCode && e.subdistrict_code === subDistrictCode
  );
  return value[0].subdistrict;
};
