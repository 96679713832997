import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';

export const MainLeft = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    gap: '15px',
    backgroundColor: '#F4F8F9',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '15vh 15px 15px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '10vh 15px 10px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '7vh 15px 10px',
    },
    '& h2': {
      color: '#000',
      fontWeight: '600',
      fontSize: 24,
      margin: 0,
    },
    '&>button, &>a': {
      width: '230px',
      maxWidth: '100%',
    },
  }));

 export const MainRight = styled(Box)(({theme}) => ({
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    gap: '15px',
    backgroundColor: '#FAFCFD',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '15vh 15px 15px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '10vh 15px 10px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '7vh 15px 10px',
    },
    '& h2': {
      color: '#000',
      fontWeight: '600',
      fontSize: 24,
      margin: 0,
    },
    '& button': {
      width: '230px',
      maxWidth: '100%',
    },
  }));

export const ColLeftStep = styled(Box)(() => ({
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    gap: '15px',
    backgroundColor: '#F4F8F9',
    height: '100%',
    padding: '30px 15px 15px',
    '& h2': {
      color: '#000',
      fontWeight: '600',
      fontSize: 20,
      margin: 0,
    },
  }));

 export const ColRightStep = styled(Box)(({theme}) => ({
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    gap: '15px',
    backgroundColor: '#FAFCFD',
    height: '100%',
    padding: '30px 15px 15px',
    '& h2': {
      color: '#000',
      fontWeight: '600',
      fontSize: 20,
      margin: 0,
    },
    '&>button, &>a, &>div>button, &>div>a': {
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100%',
        height: '38px',
      },
      [theme.breakpoints.up('md')]: {
        width: '135px',
        maxWidth: '100%',
        height: '33px',
      },
      
    },
  }));

 export const ListArea = styled('li')(({theme}) => ({
      [theme.breakpoints.down('md')]: {
        fontSize: '14px', 
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '16px', 
      },
      color: 'rgba(0, 0, 0, 0.7)',
      fontWeight:500,
  }));

export const StepBar = styled('ul')(({theme}) => ({
    position: 'relative',
    minWidth:'320px',
    listStyleType: 'none',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding:0,
    margin: '15px auto',
    '&>li': {
        flex: '1 1 0',
        position: 'relative',
        margin: '0',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 500,
        '&>*': {
            display: 'block',
            fontStyle: 'normal'
        },
        '&>i': {
            width: '20px',
            height:'20px',
            margin: '0 auto 8px',
            lineHeight: '20px',
            fontSize: '12px',
            fontWeight: 700,
            color: '#fff',
            background: 'linear-gradient(0deg, #B2ECFE, #B2ECFE), #00A9DE',
            borderRadius: '5rem',
        },
        '&>span': {

        }
    },
    '&>li.active': {
        '&>i': {background: '#00A9DE'},
    },
    '&>li::before': {
        content: '""',
        display:'block',
        position: 'absolute',
        top: '10px',
        left: '-30%',
        width: '60%',
        height: '3px',
        borderRadius: '1rem',
        background: '#B2ECFE'
    },
    '&>li.active::before': {
        background: '#00A9DE'
    },
    '&>li:first-of-type:before': {
      display:'none',
    }
}));
  