import axios, { AxiosResponse } from 'axios';
import { ApiError } from '../models/api-error-model';
import { ERROR_CODE } from '../utils/enum/common-enum';
import { env } from './environmentConfigs';

const instance = axios.create({
  baseURL: env.appSheet.url,
  timeout: env.appSheet.timeout,
  headers: {
    'Content-Type': 'application/json',
  },
});

export function get(path: string) {
  return instance
    .get(path)
    .then((result: any) => {
      if (result.status === 200) {
        return result.data;
      } else if (result.status === 204) {
        return result.status;
      }
    })
    .catch((error: any) => {
      if (error.code === 'ECONNABORTED') {
        const err = new ApiError(
          error.response?.status,
          ERROR_CODE.TIME_OUT,
          'ไม่สามารถเชื่อมต่อระบบสมาชิกได้ในเวลาที่กำหนด'
        );
        throw err;
      }

      const err = new ApiError(
        error.response?.status,
        error.response?.data.error_code,
        error.response?.data.error_message
      );
      throw err;
    });
}

export function post(path: string, payload?: any) {
  return instance
    .post(path, payload)
    .then((response: AxiosResponse) => {
      if (response && response.status === 200) {
        return response.data;
      }
    })
    .catch((error: any) => {
      if (error.code === 'ECONNABORTED') {
        const err = new ApiError(
          error.response?.status,
          ERROR_CODE.TIME_OUT,
          'ไม่สามารถเชื่อมต่อระบบสมาชิกได้ในเวลาที่กำหนด'
        );
        throw err;
      }

      const err = new ApiError(
        error.response?.status,
        error.response?.data.error_code,
        error.response?.data.error_message
      );
      throw err;
    });
}
