import { Typography } from '@mui/material';
import { ColLeftStep, ListArea } from '../../../styles/styled';


const AreaCondition = () => {
  return (
    <ColLeftStep>
      <Typography component="h2">เงื่อนไขการเสนอพื้นที่ตั้งร้าน CJ MORE</Typography>
      <ul style={{ listStyle: 'inside disc' }}>
        <ListArea>ขนาดพื้นที่ขั้นต่ำ 2 งาน หรือ 200 ตารางวา (800 ตารางเมตร) </ListArea>
        <ListArea>หน้ากว้างขั้นต่ำ 20 เมตร</ListArea>
        <ListArea>เป็นทำเลที่มีศักยภาพตรงความต้องการของทางบริษัท</ListArea>
      </ul>
    </ColLeftStep>
  );
};

export default AreaCondition;
