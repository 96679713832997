/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Button, Box, Grid, Typography, Container } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../store/store';
import { clearInputForm } from '../../store/slices/input-form-slice';
import { offerArea } from '../../services/appsheet-service';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { env } from '../../adapters/environmentConfigs';

import Image from '../../components/Image';
import IconArrow from '../../assets/icon-arrow-btn.png';
import IconMarker from '../../assets/icon-marker.png';
import { findDistrictName, findProvinceName, findSubDistrictName } from '../../utils/locationCode';
import { findAreaOptionLabel } from '../../utils/areaCleared';
import { findChannelOptionLabel } from '../../utils/fromChannel';
import { IAreaRow, IOfferAreaResponse } from '../../models/area-model';
import LoadingModal from '../../components/LoadingModal';
import { decryptData } from '../../utils/encode-decode'


const SummaryPage = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: decryptData(env.googleMap.googleMapsApiKey),
    libraries: ['places'],
  });
  
  const mapOptions = {
    disableDefaultUI: true,
    panControl: false,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    overviewMapControl: false,
    rotateControl: false,
    draggable: false, 
    scrollwheel: false, 
    disableDoubleClickZoom: true
  };
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const inputSelector = useAppSelector((state) => state.inputFormSlice);

  const [disableButton, setDisableButton] = useState(true);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [subDistrict, setSubDistrict] = useState('');
  const [token, setToken] = useState('');
  
  const onSubmit = () => {
    postAreaData()
      .then((result: IOfferAreaResponse) => {
        if (result && result.Rows.length > 0) {
          dispatch(clearInputForm());
          navigate('/success', { state: { type: 'step', data: result.Rows[0] } });
        } else {
          navigate('/error', { state: { type: 'step' } });
        }
      })
      .catch((error) => {
        navigate('/error', { state: { type: 'step' } });
      })
      .finally(() => setOpenLoadingModal(false));
  };

  const postAreaData = async () => {
    setOpenLoadingModal(true);
    let LabelChannel = '';
    if (inputSelector.fromChannel === '5') {
      LabelChannel = 'อื่นๆ: ' + inputSelector.fromOtherChannel;
    } else {
      LabelChannel = findChannelOptionLabel(inputSelector.fromChannel) + '';
    }
    const payload: IAreaRow = {
      ProposerType: inputSelector.offerType,
      ProposerFirstName: inputSelector.firstName,
      ProposerLastName: inputSelector.lastName,
      ProposerTel: inputSelector.telephone,
      ProposerEmail: inputSelector.email,
      LatLong: `${inputSelector.lat},${inputSelector.lng}`,
      NearbyPOI: inputSelector.nearbyPOI,
      LandDeedNumbers: inputSelector.landDeedNumbers,
      Subdistrict: `${subDistrict}:${inputSelector.locationCode}`,
      District: district,
      Province: province,
      LandWidth: inputSelector.landWidth,
      LandDepth: inputSelector.landDepth,
      LandAreaSQM: inputSelector.landAreaSQM,
      ExpectedRent: `${inputSelector.expectedRent}.00`,
      IsAreaCleared: inputSelector.isAreaCleared === '1' ? true : false,
      DemolitionDetails: inputSelector.demolitionDetails,
      LandDetails: inputSelector.landDetails,
      FromChannel: LabelChannel,
    };
    console.info(payload);
    return await offerArea(payload, token);
  };

  function onChange(value: any) {
    if (value) {
      setDisableButton(false);
      setToken(value);
    }
  }

  function onRecaptchaExpired() {
    setDisableButton(true);
  }

  useEffect(() => {
    if (
      !inputSelector.firstName ||
      !inputSelector.lastName ||
      !inputSelector.telephone ||
      !inputSelector.email ||
      !inputSelector.offerType ||
      !inputSelector.province ||
      !inputSelector.district ||
      !inputSelector.subDistrict ||
      !inputSelector.landWidth ||
      !inputSelector.landDepth ||
      !inputSelector.landAreaSQM ||
      !inputSelector.landDeedNumbers ||
      !inputSelector.nearbyPOI ||
      !inputSelector.isAreaCleared ||
      !inputSelector.locationCode ||
      inputSelector.lat < 0 ||
      inputSelector.lng < 0 ||
      !inputSelector.expectedRent ||
      !inputSelector.fromChannel
    ) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (inputSelector.province) {
      setProvince(findProvinceName(inputSelector.province));

      if (inputSelector.district) {
        setDistrict(findDistrictName(inputSelector.province, inputSelector.district));

        if (inputSelector.subDistrict) {
          setSubDistrict(
            findSubDistrictName(inputSelector.province, inputSelector.district, inputSelector.subDistrict)
          );
        }
      }
    }
  }, []);

  return (
    <>
      <Container sx={{ backgroundColor: '#FAFCFD', p: 2 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ mb: 5 }}>
            <Typography
              component="h2"
              sx={{ fontSize: { xs: 18, md: 24 }, mb: 3, fontWeight: 600, color: '#000', textAlign: 'center' }}
            >
              กรุณาตรวจสอบความถูกต้องอีกครั้ง
            </Typography>
            <Typography component="h3" sx={subHeadStyle}>
              ข้อมูลทั่วไป
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sx={{display:'flex', flexWrap:'nowrap'}}>
                  <Typography component="b" sx={{...label, whiteSpace:'nowrap'}}>
                    ชื่อ :
                  </Typography>
                  <Typography component="span" sx={{...inputDisplay, verticalAlign: 'top', display: 'inline-block', maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis' }}>
                    {inputSelector.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sx={{display:'flex', flexWrap:'nowrap'}}>
                  <Typography component="b" sx={{...label, whiteSpace:'nowrap'}}>
                    นามสกุล :
                  </Typography>
                  <Typography component="span" sx={{...inputDisplay, verticalAlign: 'top', display: 'inline-block', maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis' }}>
                    {inputSelector.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    เบอร์โทรศัพท์ :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.telephone}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sx={{display:'flex', flexFlow:'row nowrap'}}>
                  <Typography component="b" sx={{...label, whiteSpace:'nowrap'}}>
                    Email :
                  </Typography>
                  <Typography component="span" sx={{...inputDisplay, verticalAlign: 'top', display: 'inline-block', maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis' }}>
                    {inputSelector.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography component="b" sx={label}>
                    ประเภทผู้นำเสนอ :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.offerType}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mb: 5 }}>
            <Typography component="h3" sx={subHeadStyle}>
              ข้อมูลพื้นที่
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    จังหวัด :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {province}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    อำเภอ :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {district}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    ตำบล :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {subDistrict}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    หน้ากว้างพื้นที่เช่า :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.landWidth}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    ความลึกพื้นที่เช่า :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.landDepth}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    ขนาดพื้นที่เช่า :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.landAreaSQM}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    เลขที่โฉนด :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.landDeedNumbers}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sx={{display:'flex', flexWrap:'wrap'}}>
                  <Typography component="b" sx={{...label, whiteSpace:'nowrap'}}>
                    ชื่อบริเวณพื้นที่ใกล้เคียง :
                  </Typography>
                  <Typography component="span" sx={{...inputDisplay, verticalAlign: 'top', display: 'inline-block', maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis' }}>
                    {inputSelector.nearbyPOI}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography component="b" sx={label}>
                    เป็นพื้นที่โล่งหรือไม่ :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {findAreaOptionLabel(inputSelector.isAreaCleared)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sx={{display:'flex', flexWrap:{xs:'wrap',md:'nowrap'}, flexFlow:{xs:'column',sm:'row'} }}>
                  <Typography component="b" sx={{...label, whiteSpace:'nowrap'}}>
                    มีงานรื้อถอดอะไรบ้าง :
                  </Typography>
                  <Typography
                    component="span"
                    sx={{...inputDisplay, whiteSpace: 'pre-wrap', verticalAlign: 'top', display: 'inline-block', maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis' }}
                    dangerouslySetInnerHTML={{ __html: inputSelector.demolitionDetails }}
                  >
                    {/* {inputSelector.demolitionDetails} */}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sx={{display:'flex', flexWrap:{xs:'wrap',md:'nowrap'}, flexFlow:{xs:'column',sm:'row'} }}>
                  <Typography component="b" sx={{...label, whiteSpace:'nowrap'}}>
                    รายละเอียดพื้นที่เช่าเพิ่มเติม :
                  </Typography>
                  <Typography
                    component="span"
                    sx={{...inputDisplay, whiteSpace: 'pre-wrap', verticalAlign: 'top', display: 'inline-block', maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis' }}
                    dangerouslySetInnerHTML={{ __html: inputSelector.landDetails }}
                  >
                    {/* {inputSelector.landDetails} */}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    ละติจูด :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.lat}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography component="b" sx={label}>
                    ลองจิจูด :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.lng}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography component="b" sx={label}>
                    ตำแหน่งที่ตั้งของพื้นที่เช่า
                  </Typography>
                  {isLoaded ? (
                  <Box sx={{ height: '167px', mt: '10px',filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.05))',overflow: 'hidden', }}>
                    <GoogleMap
                      id="options-map"
                      mapContainerStyle={{ width: '482px', height: '167px', borderRadius: '10px' }}
                      center={{ lat: inputSelector.lat, lng: inputSelector.lng }}
                      zoom={16}
                      options={mapOptions}
                      clickableIcons={false}
                      
                    >
                      <Marker
                        icon={IconMarker}
                        position={{lat: inputSelector.lat, lng: inputSelector.lng}}
                      />
                    </GoogleMap>
                  </Box>
                ) : (
                  <Box />
                )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography component="h3" sx={subHeadStyle}>
              ข้อมูลเพิ่มเติม
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography component="b" sx={label}>
                    ค่าเช่าที่คาดหวังต่อเดือน :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.expectedRent}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography component="b" sx={label}>
                    ช่องทางที่ได้รับข้อมูล :
                  </Typography>
                  <Typography component="span" sx={inputDisplay}>
                    {inputSelector.fromChannel === '5' ? 'อื่นๆ' : findChannelOptionLabel(inputSelector.fromChannel)}
                  </Typography>
                </Grid>
                {inputSelector.fromOtherChannel && (
                  <Grid item xs={12} md={8} sx={{display:'flex', flexWrap:'nowrap'}}>
                    <Typography component="b" sx={{...label, whiteSpace:'nowrap'}}>
                      โปรดระบุ :
                    </Typography>
                    <Typography component="span" sx={{...inputDisplay, verticalAlign: 'top', display: 'inline-block', maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis' }}>
                      {inputSelector.fromOtherChannel}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            mb: 3,
            mx: 'auto',
            maxWidth: { xs: '50%', md: '100%' },
          }}
        >
          <ReCAPTCHA sitekey={decryptData(env.googleCaptcha.siteKey)} onChange={onChange} onExpired={onRecaptchaExpired} />
        </Box>
        <Box
          display="flex"
          sx={{
            gap: '10px',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            margin: '0 auto 20px',
            //   position: 'sticky',
            //   bottom: 0,
            px:{xs:1,md:0},
            width: {xs:'100%',md:'230px'},
            boxShadow: '0 -12px 0 -7px rgba(250, 252, 253, 0.75)',
            backgroundColor: '#FAFCFD',
            '&>*': { paddingTop: '5px', paddingBottom: '5px', height: '36px' },
          }}
        >
          <Button
            variant="contained"
            size="small"
            disabled={disableButton}
            endIcon={<Image src={IconArrow} width="32" />}
            onClick={onSubmit}
          >
            เสนอพื้นที่เช่า
          </Button>
          <Button component={Link} to="/step3" variant="outlined" size="medium">
            ย้อนกลับ
          </Button>
        </Box>
      </Container>
      <LoadingModal open={openLoadingModal} />
    </>
  );
};
const subHeadStyle = {
  fontSize: 14,
  mb: 4,
  fontWeight: 600,
  color: '#fff',
  display: 'inline-block',
  padding: '3px 10px',
  backgroundColor: '#119B4E',
  borderRadius: '5px',
};
const label = { fontSize: 16, fontWeight: 600, color: '#0A582D', marginRight: 3 };
const inputDisplay = { fontSize: 16, fontWeight: 500 };

export default SummaryPage;
