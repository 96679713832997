import { styled } from '@mui/material/styles';
import { Button, Grid, Typography, Box, TextField, Select, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../store/store';
import { ColRightStep } from '../../styles/styled';
import Image from '../../components/Image';
import CustomStepBar from './components/CustomStepBar';
import Placeholder from '../../components/Placeholder';
import { step1ValidationSchema } from '../../utils/validateSchema';
import { updateInputForm } from '../../store/slices/input-form-slice';

import IconArrow from '../../assets/icon-arrow-btn.png';
import AreaCondition from './components/AreaCondition';
import { useEffect } from 'react';

interface IFormInputs {
  firstName: string;
  lastName: string;
  telephone: string;
  email: string;
  offerType: string;
}

const StepOnePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inputSelector = useAppSelector((state) => state.inputFormSlice);

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(step1ValidationSchema),
  });

  const watchOfferType = watch("offerType");

  const onSubmit = (data: IFormInputs) => {
    dispatch(updateInputForm(data));
    navigate('/step2');
  };

  useEffect(() => {
    setValue('firstName', inputSelector.firstName);
    setValue('lastName', inputSelector.lastName);
    setValue('telephone', inputSelector.telephone);
    setValue('email', inputSelector.email);
    setValue('offerType', inputSelector.offerType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={5} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
          <AreaCondition />
        </Grid>
        <Grid item xs={12} md={7}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <ColRightStep>
              <Typography component="h2">แบบฟอร์มสำหรับเสนอพื้นที่เช่า</Typography>

              <CustomStepBar />

              <Grid
                container
                rowSpacing={{ xs: 0.5,sm: 1, md: 2 }}
                columnSpacing={{ xs: 2, sm: 2, lg: 6 }}
                sx={{ maxWidth: { xs: '100%', md: '70%' } }}
              >
                <Grid item xs={12} md={6} sx={gridStyle}>
                  <Label>ชื่อ</Label>
                  <TextField
                    {...register('firstName')}
                    error={errors.firstName ? true : false}
                    sx={textFieldStyle}
                    inputProps={{ maxLength: 100 }}
                  />
                  <Typography component="p" sx={errorBox}>
                    {errors.firstName && errors.firstName.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={gridStyle}>
                  <Label>นามสกุล</Label>
                  <TextField
                    {...register('lastName')}
                    error={errors.lastName ? true : false}
                    sx={textFieldStyle}
                    inputProps={{ maxLength: 100 }}
                  />
                  <Typography component="p" sx={errorBox}>
                    {errors.lastName && errors.lastName.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}  sx={{...gridStyle, mt:{xs:2,sm:0} }}>
                  <Label>เบอร์โทรศัพท์</Label>
                  <TextField
                    {...register('telephone')}
                    error={errors.telephone ? true : false}
                    sx={textFieldStyle}
                    inputProps={{ maxLength: 10 }}
                  />
                  <Typography component="p" sx={errorBox}>
                    {errors.telephone && errors.telephone.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={gridStyle}>
                  <Label>Email</Label>
                  <TextField
                    {...register('email')}
                    error={errors.email ? true : false}
                    sx={textFieldStyle}
                    inputProps={{ maxLength: 100 }}
                  />
                  <Typography component="p" sx={errorBox}>
                    {errors.email && errors.email.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={gridStyle}>
                  <Label>ประเภทผผู้นำเสนอ</Label>
                  <Select 
                  {...register('offerType')} 
                  error={errors.offerType ? true : false} 
                  sx={textFieldStyle}
                  value={getValues('offerType') || watchOfferType ? getValues('offerType'): ''}
                  displayEmpty
                  >
                    <MenuItem disabled value=''>
                      <Placeholder text="กรุณาเลือก" />
                    </MenuItem>
                    <MenuItem key={'0'} value='เจ้าของที่ (Landlord)'>
                    เจ้าของที่ (Landlord)
                    </MenuItem>
                    <MenuItem key={'1'} value='ตัวแทน (Agency)'>
                    ตัวแทน (Agency)
                    </MenuItem>
                  </Select>
                  <Typography component="p" sx={errorBox}>{errors.offerType && errors.offerType.message}</Typography>
                </Grid>
              </Grid>

              <Box
                display="flex"
                sx={{
                  gap: '10px',
                  flexFlow: 'row nowrap',
                  justifyContent: 'center',
                  marginTop: 4,
                  position: 'sticky',
                  bottom: 0,
                  px:{xs:1,md:0},
                  width: { xs: '100%', md: '70%' },
                  boxShadow: '0 -12px 0 -7px rgba(250, 252, 253, 0.75)',
                  backgroundColor: '#FAFCFD',
                }}
              >
                <Button type="submit" variant="contained" endIcon={<Image src={IconArrow} width="32" />}>
                  ถัดไป
                </Button>
              </Box>
            </ColRightStep>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

const Label = styled('label')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '18px',
  marginBottom: '5px',
  color: theme.palette.primary.dark,
}));

const gridStyle = {
  display: 'flex',
  flexFlow: 'column wrap',
};

const textFieldStyle = {
  '& input': {
    padding: '0 5px',
    lineHeight: '24px',
    height: '24px',
    maxWidth: '100%',
    fontSize: '14px',
  },
  '& .MuiSelect-select': {
    padding: '0 0 0 5px',
    fontSize: '14px',
  },
};

const errorBox = {
  fontSize: '12px',
  color: '#EB3223',
  lineHeight: '15px',
  marginTop: '5px',
  minHeight: '15px',
};

export default StepOnePage;
