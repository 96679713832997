import { post } from '../adapters/appSheetAdapter';
import { IAreaRoot, IAreaRow } from '../models/area-model';
import { ITrackingRoot } from '../models/tracking-model';
import { apiPath } from './path-base';

export async function tracking(OfferIDX: string, token: string) {
  try {
    const payload: ITrackingRoot = {
      Properties: { Locale: 'en-US', Timezone: 'SE Asia Standard Time'},
      Rows: [{ OfferIDX: OfferIDX }],
      "g-recaptcha-response": token,
    };
    const response = await post(apiPath.appSheet.tracking.url, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function offerArea(data: IAreaRow, token: string) {
  try {
    const payload: IAreaRoot = {
      Properties: { Locale: 'en-US', Timezone: 'SE Asia Standard Time'},
      Rows: [{ ...data }],
      "g-recaptcha-response": token,
    };    
    const response = await post(apiPath.appSheet.area.url, payload);
    return response;
  } catch (error) {
    throw error;
  }
}
