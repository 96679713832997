import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import Image from '../components/Image';
import IconHeader from '../assets/icon-header.png'


const Header = () => {
  const theme = useTheme(); 
  return (
    <Box display='flex' sx={{p:2, py:{xs:1,sm:1,md:3}, justifyContent: 'center', alignItems: 'center', flexFlow: 'row nowrap', backgroundColor: '#ECF2F4', color: theme.palette.primary.dark}}>  
        <Box component='i' sx={{mr:{xs:1,md:2},height:{xs:'41px',sm:'63px'}}}><Image src={IconHeader} height="100%"/></Box>
        <Box>
            <Typography variant='h2' component="h2" sx={{fontSize: {xs:20, md:32}}}>ทำเลดี ซีเจพร้อมเช่า</Typography>
            <Typography sx={{fontSize: {xs:14, md:18}, color: '#000'}}>CJ MORE "มากกว่าคำว่าซูเปอร์มาร์เก็ต"</Typography>
        </Box>
    </Box>
  )
}

export default Header