import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
export interface loadingModalProps {
  open: boolean;
  text?: string;
}

export default function LoadingModal(props: loadingModalProps) {
  const { open, text = 'กำลังโหลดข้อมูล...' } = props;

  return (
    <div>
      <Backdrop sx={{ color: '#FFF', zIndex: (theme) => theme.zIndex.modal + 1 }} open={open}>
        <div style={{ marginRight: '15px' }}>{text}</div>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}
