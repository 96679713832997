import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InputForm {
  firstName: string;
  lastName: string;
  telephone: string;
  email: string;
  offerType: string;
  province: string;
  district: string;
  subDistrict: string;
  landWidth: string;
  landDepth: string;
  landAreaSQM: string;
  landDeedNumbers: string;
  nearbyPOI: string;
  isAreaCleared: string;
  demolitionDetails: string;
  landDetails: string;
  expectedRent: string;
  fromChannel: string;
  fromOtherChannel: string;
  locationCode: string;
  lat: number;
  lng: number;
}

const initialState: InputForm = {
  firstName: '',
  lastName: '',
  telephone: '',
  email: '',
  offerType: '',
  province: '',
  district: '',
  subDistrict: '',
  landWidth: '',
  landDepth: '',
  landAreaSQM: '',
  landDeedNumbers: '',
  nearbyPOI: '',
  isAreaCleared: '',
  demolitionDetails: '',
  landDetails: '',
  expectedRent: '',
  fromChannel: '',
  fromOtherChannel: '',
  locationCode: '',
  lat: -1,
  lng: -1,
};

export const InputFormSlice = createSlice({
  name: 'inputFormData',
  initialState,
  reducers: {
    clearInputForm: () => initialState,
    updateInputForm: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateInputForm, clearInputForm } = InputFormSlice.actions;

export default InputFormSlice.reducer;
