import { Typography, Box } from "@mui/material";
import packageJson from "../../package.json"

export default function Footer() {
  const webVersion = packageJson.version;
  return (
    <Box component='footer' sx={{ display: 'flex',justifyContent: 'center', alignItems: 'center', background: '#119B4E',boxShadow: '0px 2px 10px 4px rgba(0, 107, 107, 0.15)', color: 'white', height: '42px'}}>
      <Typography sx={{fontSize:14, fontWeight:600}}>
        ©2015 CJ Express Group Co.Ltd. All Rights Reserved. 
        <Box component='span' sx={{fontWeight:400, fontSize:'12px'}}> - v.{webVersion}</Box>
      </Typography>
    </Box>
  );
}
