export const FromChannelOptions = [
    { value: '1', label: 'Email' },
    { value: '2', label: 'Call Center' },
    { value: '3', label: 'Facebook' },
    { value: '4', label: 'พนักงานซีเจ/CJ Employee' },
    { value: '5', label: 'อื่นๆ (โปรดระบุ)/Other (Please specify)' },
    
  ];
  
  export const findChannelOptionLabel = (value: string) => {
    const newValue = FromChannelOptions.find((e) => e.value === value);
    return newValue?.label;
  };